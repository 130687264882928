<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
				  <v-card-title primary-title>
				    <span class="text-subtitle-1">Distribución de contactos</span>
				    <v-spacer></v-spacer>

		        <v-btn 
		          class="mr-2" 
		          small dark color="green"
		          @click="exportExcel( diapositivas  , 'ASIGNACION_TWILIO')"
		          tile
		        >
		          <v-icon small>mdi-microsoft-excel</v-icon>
		        </v-btn>

		        <v-btn
		          color="primary"
		          dark
		          class="text-capitalize"
		          @click="initialize()"
		          small
		          tile
		        >
		          <v-icon small>mdi-refresh</v-icon>
		        </v-btn>
		        
				  </v-card-title>

				  <v-card-text>
				  	<v-row>

		          <v-col cols="12" sm="6" md="6">
		            <v-radio-group
		              v-model="escuela"
		              row
		            >
		              <v-radio
		                label="Inbi"
		                :value="1"
		              ></v-radio>
		              <v-radio
		                label="Fast"
		                :value="2"
		              ></v-radio>
		              <v-radio
		                label="Todas"
		                :value="3"
		              ></v-radio>
		            </v-radio-group>
		          </v-col>

				  		<v-col cols="12" sm="6" md="6">
				  			<v-text-field
				  			  name="name"
				  			  label="Buscar"
				  			  id="id"
				  			  filled
				  			  dense
				  			  single-line
				  			  hide-details
				  			  append-icon="mdi-magnify"
				  			  v-model="search"
				  			>
				  			</v-text-field>
				  		</v-col>
				  	</v-row>
				    <v-row>
		          <v-col cols="12">
		            <div>Planteles Sin Vendedora</div>
		            <div v-for="(plantel, i ) in plantelesSinVendedora">
		              {{ plantel.plantel }}
		            </div>
		            <div v-if="!plantelesSinVendedora.length">Todos los planteles tienen vendedora</div>
		          </v-col>

		          <!-- TABLA DE PLANTELES -->
				    	<v-col cols="12">
							  <v-card-title class="text-subtitle-1">
				          Vendedoras asignadas al plantel
				          <v-spacer></v-spacer>
				          <v-btn
				            color="orange"
				            dark
				            class="mr-2"
				            @click="dialog = true"
				            small
				            tile
				          >
				            <v-icon small>mdi-plus</v-icon>
				          </v-btn>
				        </v-card-title>
				        <v-card-text>
				          <v-data-table
				            :headers="headersVendedoras"
				            :items="filterVendedoras"
				            class="elevation-0"
				            :search="search"
				            dense
				          >
				            <template v-slot:item.nombre="{ item }">
				              <span>
				                <v-icon v-if="item.sobrecarga" color="red" small left>mdi-checkbox-blank-circle</v-icon>
				                {{ item.nombre }}
				              </span>
				            </template>

				            <template v-slot:item.nombresTwilios="{ item }">
				              <span v-for="(tw, i) in item.nombresTwilios" @click="abrirTablaTwilio( item )" v-if="item.twilios.length > 0 ">
				                {{ tw }}<br/>
				              </span>

				              <v-btn color="primary" small @click="abrirTablaTwilio( item )" v-if="!item.twilios.length" >Agregar</v-btn>
				            </template>

				            <template v-slot:item.reloj="{ item }">
				              <v-chip v-if="item.reloj"  color="green" small dark>En sucursal</v-chip>
				              <v-chip v-if="!item.reloj" color="red"   small dark>Salió</v-chip>
				            </template>

				            <template v-slot:item.porcentaje_plantel="props">
				              <v-edit-dialog
				                :return-value.sync="props.item.porcentaje_plantel"
				                @save="savePorcentaje( props.item, 1 )"
				              >
				                {{ props.item.porcentaje_plantel }}
				                <template v-slot:input>
				                  <v-text-field
				                    v-model="props.item.porcentaje_plantel_nuevo"
				                    label="Agregar el % con plantel"
				                    single-line
				                    counter
				                  ></v-text-field>
				                </template>
				              </v-edit-dialog>
				            </template>

				            <template v-slot:item.porcentaje_sin_plantel="props">
				              <v-edit-dialog
				                :return-value.sync="props.item.porcentaje_sin_plantel"
				                @save="savePorcentaje( props.item, 2 )"
				              >
				                {{ props.item.porcentaje_sin_plantel }}
				                <template v-slot:input>
				                  <v-text-field
				                    v-model="props.item.porcentaje_sin_plantel_nuevo"
				                    label="Agregar el % con plantel"
				                    single-line
				                    counter
				                  ></v-text-field>
				                </template>
				              </v-edit-dialog>
				            </template>

				            <template v-slot:item.actions="{ item }">
				              <v-btn 
				                color="error" 
				                x-small
				                @click="deleteItem(item)"
				                class="mr-2"
				              >
				                <v-icon small> mdi-delete</v-icon>
				              </v-btn>
				            </template>

				          </v-data-table>
				        </v-card-text>
				    	</v-col>
				    </v-row>
				  </v-card-text>
				  <v-card-actions>
				  	<v-btn 
				  		color="black"
				  		dark
				  		small
				  		tile
				  		@click="dialogTwilio.estatus = false"
				  	>
				  		<v-icon small left>mdi-close</v-icon>
				  		Cerrar
				  	</v-btn>
		        <v-spacer></v-spacer>
		      </v-card-actions>
				</v-card>
			</v-col>
		</v-row>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idtwilio_vendedoras }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-col cols="12">
          	<v-autocomplete
		  			  label="Vendedoras"
		  			  id="id"
		  			  filled
		  			  dense
		  			  hide-details
		  			  v-model="editedItem.id_vendedora"
		  			  :items="usuarios"
		  			  item-text="nombre_completo"
		  			  item-value="id_usuario"
		  			  clearable
		  			></v-autocomplete>
      		</v-col>

      		<!-- <v-col cols="12">
          	<v-text-field
          	  label="Prioridad"
          	  id="id"
          	  type="number"
          	  filled
          	  dense
          	  v-model="editedItem.prioridad"
		  			  hide-details
          	></v-text-field>
      		</v-col> -->

          <v-col cols="12">
            <v-text-field
              label="% del plantel"
              id="id"
              type="number"
              filled
              dense
              v-model="editedItem.porcentaje_plantel"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="% sin plantel"
              id="id"
              type="number"
              filled
              dense
              v-model="editedItem.porcentaje_sin_plantel"
              hide-details
            ></v-text-field>
          </v-col>

      		<v-col cols="12">
          	<v-autocomplete
		  			  label="Plantel"
		  			  id="id"
		  			  filled
		  			  dense
		  			  hide-details
		  			  v-model="editedItem.id_plantel"
		  			  :items="planteles"
		  			  item-text="plantel"
		  			  item-value="id_plantel"
		  			  clearable
		  			></v-autocomplete>
      		</v-col>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialog = false"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para editar los twilios -->
    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialogTwilios"
      max-width="600px"
      persistent
    >
      <v-card v-if="twilios">
        <v-card-title>
          <span class="text-subtitle-1">Permisos de CRM</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>Vendedora: {{ twilios.nombre }}</strong>
          </span>
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="12" md="10">
              <v-autocomplete
                label="Vendedoras"
                id="id"
                filled
                dense
                v-model="usuarioAreemplazar"
                :items="usuarios"
                item-text="nombre_completo"
                item-value="id_usuario"
                clearable
                return-object
                @keyup.enter="addDetalle( )"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn color="orange">
                <v-icon tile dark @click="addDetalle( )">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersTwilios"
            :items="twilios.twilios"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >

            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="error" 
                x-small
                @click="deleteReemplazo(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialogTwilios = false"
            tile
            dense
          >
            <v-icon left small>mdi-close</v-icon>
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogTwiliosVendedora"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Planteles por vendedora</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          </span>
        </v-card-title>

        <v-card-text>

          <v-data-table
            :headers="headers2"
            :items="plantelesVendedora"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >

            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="primary" 
                x-small
                @click="editItem(item)"
                class="mr-2"
              >
                <v-icon small>mdi-magnify</v-icon>
              </v-btn>

              <v-btn 
                color="error" 
                x-small
                @click="deleteItem(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>

            </template>


            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
                small
              >
                Actualizar
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            icon
            small
            @click="dialogTwiliosVendedora = false"
            tile
            dense
            absolute
            top
            right
          >
            <v-icon left small>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVendedoras"
      overlay
      max-width="1200px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Vendedoras asignadas al plantel
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            dark
            class="mr-2"
            @click="dialog = true"
            small
            tile
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersVendedoras"
            :items="filterVendedoras"
            class="elevation-0"
            :search="search"
            :mobile-breakpoint="100"
            dense
          >
            
            <template v-slot:item.nombre="{ item }">
              <span>
                <v-icon v-if="item.sobrecarga" color="red" small left>mdi-checkbox-blank-circle</v-icon>
                {{ item.nombre }}
              </span>
            </template>

            <template v-slot:item.lada="props">
                <v-edit-dialog
                  :return-value.sync="props.item.lada"
                  @save="saveLada( props.item )"
                >
                  {{ props.item.lada }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.lada"
                      label="Agrega la lada, no debe tener espacios"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

            <template v-slot:item.nombresTwilios="{ item }">
              <span v-for="(tw, i) in item.nombresTwilios" @click="abrirTablaTwilio( item )" v-if="item.twilios.length > 0 ">
                {{ tw }}<br/>
              </span>

              <v-btn color="primary" small @click="abrirTablaTwilio( item )" v-if="!item.twilios.length" >Agregar</v-btn>
            </template>

            <template v-slot:item.reloj="{ item }">
              <v-chip v-if="item.reloj"  color="green" small dark>En sucursal</v-chip>
              <v-chip v-if="!item.reloj" color="red"   small dark>Salió</v-chip>
            </template>

            <template v-slot:item.porcentaje_plantel="props">
              <v-edit-dialog
                :return-value.sync="props.item.porcentaje_plantel"
                @save="savePorcentaje( props.item, 1 )"
              >
                {{ props.item.porcentaje_plantel }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.porcentaje_plantel_nuevo"
                    label="Agregar el % con plantel"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.porcentaje_sin_plantel="props">
              <v-edit-dialog
                :return-value.sync="props.item.porcentaje_sin_plantel"
                @save="savePorcentaje( props.item, 2 )"
              >
                {{ props.item.porcentaje_sin_plantel }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.porcentaje_sin_plantel_nuevo"
                    label="Agregar el % con plantel"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="error" 
                x-small
                @click="deleteItem(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>


<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import mixinLeads            from '@/mixins/mixinLeads'
  import nuevo_lead            from '@/components/leads/nuevo_lead.vue';
  import basicosTablas         from '@/mixins/basicosTablas'
  import AsignacionTwilio      from '@/components/whatsapp/AsignacionTwilio'
  import SeguimientoVendedora  from '@/components/leads/SeguimientoVendedora'
  import DiferenciasVendedora  from '@/components/leads/DiferenciasVendedora'
  import ReasignarVendedora    from '@/components/leads/ReasignarVendedora'
  import ReporteEfectividad    from '@/components/leads/ReporteEfectividad'
  import SucursalInteres       from '@/components/leads/SucursalInteres'
  import BuenSeguimiento       from '@/components/leads/BuenSeguimiento'

  export default {
    components:{
      Alerta,
      carga,
      nuevo_lead,
      AsignacionTwilio,
      SeguimientoVendedora,
      DiferenciasVendedora,
      ReasignarVendedora,
      ReporteEfectividad,
      SucursalInteres,
      BuenSeguimiento
    },

    mixins: [ validarErrores, funcionesExcel, mixinLeads, basicosTablas ],
    
    //Data
    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      contador : 0,
      vendedorasAll:[],
      plantelesVendedora:[],
      dialogTwiliosVendedora: false,
      plantelesSinVendedora: [],
      escuela: 3,

      plantelSeleccionado: null,
      dialogVendedoras: false,
      porcentaje_plantel: 0,

      //datos que se desplegaran en las 2 tablas
      prospectos: [],
      plantelesSinVendedora: [],
      headersVendedoras: [
        { text: 'ID'                 , value: 'idtwilio_vendedoras'    },
        { text: 'Vendedora'          , value: 'nombre'                 },
        { text: 'Reloj'              , value: 'reloj'                  },
        { text: 'Plantel'            , value: 'plantel'                },
        { text: 'Twilios'            , value: 'nombresTwilios'         },
        { text: 'Actions'            , value: 'actions', sortable: false },
      ],
      usuarios: [],
      usuario: null,
      dialogUsuario: false,

      search: "",

      vendedoras: [],

      search2:'',
      contactosActivos:[],

      contactosVsFecha:[],
      contactosInscritos:[],

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',

      headers3:[
        { text: "Folio"         , value: "folio"            },
        { text: "Nombre"        , value: "nombre_completo"  },
        { text: "Plantel"       , value: "plantel"          },
        { text: "Usuario"       , value: "twilio"           },
        { text: "Sistema"       , value: "sistema"          },
        { text: "Mensaje"       , value: "mensaje"          },
        { text: "Fecha"         , value: "fecha_creacion"   },
      ],

      headers2: [
        { text: 'Plantel'    , value: 'plantel'              },
        { text: 'Zona'       , value: 'zona'                 },
        { text: 'Prioridad'  , value: 'prioridad'            },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      headersTwilios: [
        { text: 'Vendedora'  , value: 'crm'                  },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 1,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
        respondio: 0,
      },

      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,
      fuentes:[],

      tablaContactos: [],
      dialogMensajes: false,

      dialogTwilio: {
        estatus: false
      },

      dialog: false,

      dialogDelete: false,


      dialogVendedora: {
        estatus: false
      },

      dialogDiferencias: {
        estatus: false
      },

      dialogResaginar:{
        estatus: false
      },

      dialogEfectividad:{
        estatus: false
      },

      dialogReporteSucursal:{
        estatus: false
      },

      dialogBuenSeguimiento:{
        estatus: false
      },

      baseEscuela:[],
      opZona: 'TODOS',

      tablaAlumnos:[],
      dialogAlumnos: false,
      totalMatriculas:[],
      search:'',
      vendedoras: [],
      usuarios: [],
      planteles:[],
      twilios: null,
      dialogTwilios: false,
      usuarioAreemplazar: null,

      contador : 0,
      vendedorasAll:[],
      plantelesVendedora:[],
      dialogTwiliosVendedora: false,
      plantelesSinVendedora: [],
      escuela: 3,

      plantelSeleccionado: null,
      dialogVendedoras: false,
      porcentaje_plantel: 0,

    }),

    //Computed
    computed: {

      ...mapGetters('login',['getdatosUsuario']),


      dialog (val) {
        val || this.close()
      },

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar un LEAD' : 'Editar LEAD'
      },

      filterVendedoras( ){
      	let data = this.vendedorasAll

      	if( this.escuela != 3 ){
        	data = this.escuela == 1 ? data.filter( el => !el.nombre_plantel.match('FAST') ) : data.filter( el => el.nombre_plantel.match('FAST') )
      	}

        return this.vendedorasAll

      },

    },

    watch: {
      dialogUsuario(val) {
        val || this.close();
      },
    },

    async created() {
      await this.initialize();
    },

    //Methods
    methods: {

      //Obtener prospectos y usuarios
      async initialize() {
        this.cargar = true;
        this.vendedoras = []

        return axios.post('twilio.vendedoras').then(response => {
          this.planteles             = response.data.planteles
          this.vendedoras            = response.data.vendedorasAll
          this.usuarios              = response.data.usuarios
          this.vendedorasAll         = response.data.vendedorasAll
          this.plantelesSinVendedora = response.data.plantelesSinVendedora

          this.cargar = false;
        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },

      abrirTablaTwilio( item ){

        this.twilios = item

        console.log( this.twilios )

        this.dialogTwilios = true
      
      },

      addDetalle( ){

        if( this.usuarioAreemplazar ){

          // Buscar ese usuario a reemplazar en otros usuarios
          const { id_usuario } = this.usuarioAreemplazar

          for( const i in this.vendedoras ){

            const { twilios, nombre } = this.vendedoras[i]

            for( const j in twilios ){

              const { usuario_crm } = twilios[j]

              if( usuario_crm == id_usuario ){

                if( this.contador == 0 ){
                  
                  this.contador = 1
                  return this.validarErrorDirecto(`La vendedora ${nombre} ya cuenta con ese acceso, si estás consciente de eso, solo cierra está ventana e intenta nuevamente!`)

                }
              }

            }
          }


          this.cargar = true

          const payload = {

            idusuario  : this.twilios.id_vendedora,
            usuario_crm: this.usuarioAreemplazar.id_usuario,

          }

          // Lo mandapos por el EP
          this.$http.post('twilio.acceso.crm', payload).then(response=>{
            this.dialogTwilios      = false
            this.usuarioAreemplazar = null
            this.twilios            = null
            this.cargar = false
            this.contador = 0
            this.initialize()
            this.close()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })


        }

      },


      deleteReemplazo( item ){

        // Lo mandapos por el EP
        this.$http.post('twilio.acceso.crm.delete', item).then(response=>{
          this.dialogTwilios      = false
          this.usuarioAreemplazar = null
          this.twilios            = null
          this.initialize()
          this.close()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },
    },
  };
</script>